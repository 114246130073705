.gallery {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(to right, #48086e, #1a0229);
  align-items: center;
  justify-content: center;
}

.gallery-text {
  width: 100%;
  height: 120px;
  background: linear-gradient(to right, #48086e, #1a0229);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.gallery-text h1 {
  color: #fff;
  font-size: 50px;
  position: relative;
}

.gallery-btn {
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(to right, #48086e, #1a0229);
}

.gradient-button {
  position: relative;
  background: none;
  /* Remove default button background */
  color: white;
  /* Text color */
  padding: 10px 20px;
  /* Adjust padding as needed */
  font-size: 16px;
  /* Adjust font size as needed */
  border: none;
  /* No default border */
  border-radius: 20px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
  margin: 0 25px;
  z-index: 1;
  /* Ensure button content is above the pseudo-elements */
  overflow: hidden;
  /* Ensure pseudo-elements do not overflow */
}

.gradient-button::before,
.gradient-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  /* Match the button's rounded corners */
  z-index: -1;
  /* Place behind the button content */
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
}

.gradient-button::before {
  background: linear-gradient(to right, #4b08c6, #d201de);
  /* Gradient background */
  opacity: 0;
  /* Initially hide the gradient background */
}

.gradient-button::after {
  background: linear-gradient(to right, #4b08c6, #d201de);
  /* Gradient border background */
  padding: 2px;
  /* Create border effect */
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.gradient-button:hover::before {
  opacity: 1;
  /* Show the gradient background on hover */
}

.gallery-img {
  width: 100%;
  height: 800px;
  display: flex;
  background: linear-gradient(to right, #48086e, #1a0229);
  position: relative;
}


.monkey4 {
  height: 380px;
  width: 295px;
  position: absolute;
  margin-left: 600px;
  margin-top: 100px;
}

.monkey5 {
  height: 445px;
  width: 355px;
  position: absolute;
  margin-top: 300px;
  margin-left: 50px;
}

.monkey6 {
  height: 445px;
  width: 355px;
  position: relative;
  margin-left: 1100px;
  margin-top: 300px;
}

.btn-arrow-left {
  display: flex;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  padding: 5px;
  border: 2px solid transparent;
  background: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  background-clip: padding-box;
  margin-left: 440px;
  margin-top: 370px;
}

.btn-arrow-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  /* Match the button's rounded corners */
  padding: 3px;
  /* Match the border width */
  background: linear-gradient(to bottom, #4b08c6, #d201de);
  /* Gradient background */
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.btn-arrow-left:hover {
  filter: brightness(1.2);
}

.btn-arrow-right {
  display: flex;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  padding: 5px;
  border: 2px solid transparent;
  background: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  background-clip: padding-box;
  margin-left: 990px;
  margin-top: 370px;
}

.btn-arrow-right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  /* Match the button's rounded corners */
  padding: 3px;
  /* Match the border width */
  background: linear-gradient(to bottom, #4b08c6, #d201de);
  /* Gradient background */
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.btn-arrow-right:hover {
  filter: brightness(1.2);
}

.line4 {
  height: 2px;
  width: 550px;
  background: linear-gradient(to right, #FF01F7, #762FFE);
}

@media (max-width: 480px) {
  .gallery {
    min-height: 50vh;
  }

  .gallery-text h1 {
    font-size: 36px;
    /* Adjust font size */
    text-align: center;
    margin-top: 20px;
    /* Adjust margin */
  }

  .gallery-btn {
    flex-wrap: wrap;
    /* Allow buttons to wrap to the next line */
    height: auto;
    /* Adjust height */
  }

  .gradient-button {
    margin: 10px;
    /* Adjust margin */
    padding: 8px 15px;
    /* Adjust padding */
    font-size: 14px;
    /* Adjust font size */
  }

  .gallery-img {
    flex-direction: column;
    /* Stack images vertically */
    height: auto;
    /* Adjust height */
    align-items: center;
    /* Center images */
  }

  .monkey4 {
    position: relative;
    /* Stack images vertically */
    margin-top: 100px;
    /* Adjust margin */
    margin-left: 0;
    height: auto;
    /* Adjust height */
    width: 35%;
    /* Adjust width */
  }

  .monkey5 {
    position: relative;
    /* Stack images vertically */
    margin: 0 0;
    /* Adjust margin */
    height: auto;
    /* Adjust height */
    width: 50%;
    /* Adjust width */
    transform: rotate(30deg);
  }

  .monkey6 {
    position: relative;
    /* Stack images vertically */
    margin: 0 0;
    /* Adjust margin */
    height: auto;
    /* Adjust height */
    width: 50%;
    /* Adjust width */
    transform: rotate(-30deg);
  }

  .btn-arrow-left {
    height: 50px;
    /* Adjust size */
    width: 50px;
    /* Adjust size */
    margin: 20px;
    /* Adjust margin */
    position: absolute;
    /* Adjust position */
    transform: rotate(90deg);
  }

  .btn-arrow-right {
    height: 50px;
    /* Adjust size */
    width: 50px;
    /* Adjust size */
    margin: 20px;
    /* Adjust margin */
    position: relative;
    /* Adjust position */
    transform: rotate(90deg);
  }

  .line4 {
    margin-top: -120px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .gallery {
    min-height: 50vh;
    margin-top: -100px;
  }

  .gallery-text h1 {
    font-size: 36px;
    /* Adjust font size */
    text-align: center;
    margin-top: 20px;
    /* Adjust margin */
  }

  .gallery-btn {
    flex-wrap: wrap;
    /* Allow buttons to wrap to the next line */
    height: auto;
    /* Adjust height */
  }

  .gradient-button {
    margin: 10px;
    /* Adjust margin */
    padding: 8px 15px;
    /* Adjust padding */
    font-size: 14px;
    /* Adjust font size */
  }

  .gallery-img {
    flex-direction: column;
    /* Stack images vertically */
    height: auto;
    /* Adjust height */
    align-items: center;
    /* Center images */
  }

  .monkey4 {
    position: relative;
    /* Stack images vertically */
    margin-top: 100px;
    /* Adjust margin */
    margin-left: 0;
    height: auto;
    /* Adjust height */
    width: 35%;
    /* Adjust width */
  }

  .monkey5 {
    position: relative;
    /* Stack images vertically */
    margin: 0 0;
    /* Adjust margin */
    height: auto;
    /* Adjust height */
    width: 50%;
    /* Adjust width */
    transform: rotate(30deg);
  }

  .monkey6 {
    position: relative;
    /* Stack images vertically */
    margin: 0 0;
    /* Adjust margin */
    height: auto;
    /* Adjust height */
    width: 50%;
    /* Adjust width */
    transform: rotate(-30deg);
  }

  .btn-arrow-left {
    height: 50px;
    /* Adjust size */
    width: 50px;
    /* Adjust size */
    margin: 20px;
    /* Adjust margin */
    position: absolute;
    /* Adjust position */
    transform: rotate(90deg);
  }

  .btn-arrow-right {
    height: 50px;
    /* Adjust size */
    width: 50px;
    /* Adjust size */
    margin: 20px;
    /* Adjust margin */
    position: relative;
    /* Adjust position */
    transform: rotate(90deg);
  }

  .line4 {
    margin-top: -120px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .gallery {
    min-height: 30vh;
    margin-top: -120px;
  }

  .gallery-text h1 {
    font-size: 36px;
    /* Adjust font size */
    text-align: center;
    margin-top: 20px;
    /* Adjust margin */
  }

  .gallery-btn {
    flex-wrap: wrap;
    /* Allow buttons to wrap to the next line */
    height: auto;
    /* Adjust height */
  }

  .gradient-button {
    margin: 10px;
    /* Adjust margin */
    padding: 8px 15px;
    /* Adjust padding */
    font-size: 14px;
    /* Adjust font size */
  }

  .gallery-img {
    flex-direction: column;
    /* Stack images vertically */
    height: 500px;
    /* Adjust height */
    align-items: center;
    /* Center images */
  }

  .monkey4 {
    position: absolute;
    /* Stack images vertically */
    margin-top: 120px;
    /* Adjust margin */
    margin-left: 0;
    height: auto;
    /* Adjust height */
    width: 25%;
    /* Adjust width */
  }

  .monkey5 {
    position: absolute;
    /* Stack images vertically */
    margin-top: 250px;
    margin-right: 550px;
    height: auto;
    /* Adjust height */
    width: 30%;
    /* Adjust width */
  }

  .monkey6 {
    position: relative;
    /* Stack images vertically */
    margin-top: 250px;
    margin-left: 500px;
    height: auto;
    /* Adjust height */
    width: 30%;
    /* Adjust width */
  }

  .btn-arrow-left {
    height: 60px;
    /* Adjust size */
    width: 60px;
    /* Adjust size */
    margin-top: 250px;
    margin-right: 750px;
    position: absolute;
    /* Adjust position */
  }

  .btn-arrow-right {
    height: 60px;
    /* Adjust size */
    width: 60px;
    /* Adjust size */
    margin-top: 250px;
    margin-left: 300px;
    position: absolute;
    /* Adjust position */
  }
}

@media (min-width:1024px) and (max-width:1200px) {
  .gallery {
    margin-top: -50px;
  }

  .gallery-text h1 {
    font-size: 36px;
    /* Adjust font size */
    text-align: center;
    margin-top: 20px;
    /* Adjust margin */
  }

  .gallery-btn {
    flex-wrap: wrap;
    /* Allow buttons to wrap to the next line */
    height: auto;
    /* Adjust height */
  }

  .gradient-button {
    margin: 10px;
    /* Adjust margin */
    padding: 8px 15px;
    /* Adjust padding */
    font-size: 14px;
    /* Adjust font size */
  }

  .gallery-img {
    flex-direction: column;
    /* Stack images vertically */
    height: 500px;
    /* Adjust height */
    align-items: center;
    /* Center images */
  }

  .monkey4 {
    position: absolute;
    /* Stack images vertically */
    margin-top: 120px;
    /* Adjust margin */
    margin-left: 0;
    height: auto;
    /* Adjust height */
    width: 25%;
    /* Adjust width */
  }

  .monkey5 {
    position: absolute;
    /* Stack images vertically */
    margin-top: 250px;
    margin-right: 730px;
    height: auto;
    /* Adjust height */
    width: 30%;
    /* Adjust width */
  }

  .monkey6 {
    position: relative;
    /* Stack images vertically */
    margin-top: 250px;
    margin-left: 680px;
    height: auto;
    /* Adjust height */
    width: 30%;
    /* Adjust width */
  }

  .btn-arrow-left {
    height: 60px;
    /* Adjust size */
    width: 60px;
    /* Adjust size */
    margin-top: 250px;
    margin-right: 850px;
    position: absolute;
    /* Adjust position */
  }

  .btn-arrow-right {
    height: 60px;
    /* Adjust size */
    width: 60px;
    /* Adjust size */
    margin-top: 250px;
    margin-left: 400px;
    position: absolute;
    /* Adjust position */
  }

  .line4 {
    position: relative;
    margin-top: 250px;
  }
}