nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to right, #48086e, #1a0229);
  color: white;
}

.logo {
  height: 100px;
  width: 570px;
  margin-left: 50px;
}

.logo h1 {
  font-size: 2.5rem;
  margin-top: 30px;
  background: -webkit-linear-gradient(#B53DFF, #EA00FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
}

nav img {
  height: 40px;
  cursor: pointer;
}

.logo-img {
  margin-top: 40px;
  height: 60px;
  margin-left: 20px;
}

.logo-img:hover {
  filter: brightness(1.1);
}

nav ul {
  display: flex;
  list-style: none;
  font-size: 16px;
  font-stretch: expanded;
  justify-content: center;
  width: 100%;
  margin-right: 0;
  padding: 0;
}

nav ul li {
  position: relative;
  margin: 0 2rem;
  cursor: pointer;
}

nav ul li:hover {
  font-weight: 500;
}

nav ul li::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #9C7FFF, #B53DFF, #EA00FF);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

nav ul li:hover::after {
  transform: scaleX(1);
}

.connect-btn {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.con-btn {
  display: inline-block;
  align-items: center;
  background: linear-gradient(to right, #4b08c6, #d201de);
  border-radius: 20px;
  height: 50px;
  width: 180px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
  transition: 0.3s ease, transform 0.3s ease;
  /* Smooth transition for the hover effect */
  margin-right: 10px;
}

.con-btn:hover {
  transform: scale(1.05);
  /* Slightly scale up the button on hover */
  filter: brightness(1.1);
}

.signup-button {
  height: 60px;
  width: 60px;
  background: transparent;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.sign-btn {
  display: flex;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 5px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #995ffd, #ea59f6);
  border: 2px solid transparent;
  transition: all 0.3s ease;
  background-clip: padding-box;
  cursor: pointer;
  position: absolute;
}

.sign-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  padding: 2px;
  background: linear-gradient(to right, #cf2a9b, #5c37c8);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.sign-btn:hover::before {
  filter: brightness(1.2);
}

.sign-btn img {
  height: 25px;
  width: 25px;
}

.hamburger-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-icon .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 10px;
}

@media (max-width: 768px) {
  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, #48086e, #1a0229);
    padding: 1rem 0;
    z-index: 1000;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    margin: 1rem 0;
    text-align: center;
  }

  .logo {
    margin-left: 10px;
  }

  .logo h1 {
    font-size: 2rem;
  }

  .connect-btn {
    display: none;
    margin: 1rem auto;
  }

  .signup-button {
    display: none;
    margin: 1rem auto;
  }

  .nav-links.open .connect-btn,
  .nav-links.open .signup-button {
    display: flex;
  }

  .hamburger-icon {
    display: flex;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .logo-img {
    height: 80px;
  }

  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, #48086e, #1a0229);
    padding: 1rem 0;
    z-index: 1000;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    margin: 1rem 0;
    text-align: center;
  }

  .connect-btn {
    display: none;
    margin: 1rem auto;
  }

  .signup-button {
    display: none;
    margin: 1rem auto;
  }

  .nav-links.open .connect-btn,
  .nav-links.open .signup-button {
    display: flex;
  }

  .hamburger-icon {
    display: flex;
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  nav ul li {
    margin: 0 1rem;
  }
}