.clients {
  width: 100%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #48086e, #1a0229);
  position: relative;
}

.line {
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #FF01F7, #762FFE);
  margin: 30px 0;
  position: relative;
}

.container {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container h1 {
  color: white;
  font-size: 45px;
  margin-right: 20px;
  position: relative;
}

.container p {
  color: white;
  font-size: 16px;
  position: relative;
}

.line2 {
  width: 3px;
  height: 60px;
  background: linear-gradient(to bottom, #FF01F7, #762FFE);
  margin: 0 60px;
  position: relative;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
  }

  .container h1 {
    font-size: 30px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .container p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }

  .line2 {
    width: 30%;
    height: 2px;
    margin: 20px 0;
    background: linear-gradient(to right, #FF01F7, #762FFE);
  }
}

/* Media query for tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .container {
    flex-direction: row;
    height: auto;
  }

  .container h1 {
    font-size: 35px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .container p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }

  .line2 {
    width: 2px;
    height: 40px;
    margin: 20px 40px;
    background: linear-gradient(to right, #FF01F7, #762FFE);
  }
}